/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { getColor, Theme } from 'styles/theme';

export const lists = css<{ theme: Theme }>`
  ul {
    padding-inline-start: 16px;
    list-style: outside;
  }

  ol {
    padding-inline-start: 16px;
  }

  li {
    list-style-position: outside;
    margin-bottom: 8px;
  }

  li::marker {
    color: ${getColor('neutralDarkGray')};
  }
`;

export const bulletList = css`
  ul, ol {
    padding-inline-start: 16px;
    list-style: outside;
  }

`;
